import serviceOne from "../assets/images/services/icon-1.png";
import serviceTwo from "../assets/images/services/icon-2.png";
import serviceThree from "../assets/images/services/icon-3.png";

const serviceData = [
  {
    id: 1,
    iconSrc: serviceOne,
    titleEn: "Web Development Templates",
    titleDe: "Webentwicklungsvorlagen",
    dateEn: "Tue 18 Aug 2024",
    dateDe: "Dienstag, 18. August 2024",
    descriptionEn:
      "From design to putting your website online, we create your custom website and web application.",
    descriptionDe:
      "Vom Design bis zur Online-Schaltung Ihrer Website erstellen wir Ihre individuelle Website und Webanwendung.",
    contentEn: `
      <h2>The Essence of Web Development Templates</h2>
      <p>
        Web Development Templates are a fundamental tool in the process of creating websites and web applications. They streamline the development process and provide a structured foundation for building your online presence. Dive into the world of Web Development Templates to understand their significance and how they can expedite your project.
      </p>
      
      <h2>Key Features of Web Development Templates</h2>
      <ul>
        <li>Efficiency: Using templates saves time and effort in web development, allowing you to focus on customizing your site's content and design.</li>
        <li>Responsive Design: Templates often come with built-in responsiveness, ensuring your site looks great on various devices.</li>
        <li>Customizability: While templates provide a starting point, they can be tailored to match your brand and unique requirements.</li>
        <li>Scalability: Templates can be scaled to accommodate the growth of your website or web application.</li>
        <li>Pre-built Components: Many templates include pre-built UI components and features, reducing development complexity.</li>
        <li>Optimized Performance: Templates are often optimized for performance and speed, enhancing the user experience.</li>
      </ul>
      
      <h2>Choosing the Right Template</h2>
      <p>
        Selecting the right web development template is crucial. Consider your project's goals, design preferences, and functionality requirements when making your choice. With the right template, you can kickstart your web development journey with confidence.
      </p>
    `,
    contentDe: `
      <h2>Die Bedeutung von Webentwicklungsvorlagen</h2>
      <p>
        Webentwicklungsvorlagen sind ein grundlegendes Werkzeug im Prozess der Erstellung von Websites und Webanwendungen. Sie vereinfachen den Entwicklungsprozess und bieten eine strukturierte Grundlage für den Aufbau Ihrer Online-Präsenz. Tauchen Sie in die Welt der Webentwicklungsvorlagen ein, um ihre Bedeutung zu verstehen und wie sie Ihr Projekt beschleunigen können.
      </p>
      
      <h2>Wichtige Merkmale von Webentwicklungsvorlagen</h2>
      <ul>
        <li>Effizienz: Die Verwendung von Vorlagen spart Zeit und Aufwand in der Webentwicklung und ermöglicht es Ihnen, sich auf die Anpassung des Inhalts und des Designs Ihrer Website zu konzentrieren.</li>
        <li>Responsive Design: Vorlagen sind oft von Haus aus responsiv gestaltet, sodass Ihre Website auf verschiedenen Geräten großartig aussieht.</li>
        <li>Individualisierbarkeit: Obwohl Vorlagen einen Ausgangspunkt bieten, können sie an Ihre Marke und Ihre individuellen Anforderungen angepasst werden.</li>
        <li>Skalierbarkeit: Vorlagen können für das Wachstum Ihrer Website oder Webanwendung angepasst werden.</li>
        <li>Vordefinierte Komponenten: Viele Vorlagen enthalten vordefinierte UI-Komponenten und Funktionen, die die Entwicklung vereinfachen.</li>
        <li>Optimierte Leistung: Vorlagen sind oft auf Leistung und Geschwindigkeit optimiert, um die Benutzererfahrung zu verbessern.</li>
      </ul>
      
      <h2>Die richtige Vorlage auswählen</h2>
      <p>
        Die Auswahl der richtigen Webentwicklungsvorlage ist entscheidend. Berücksichtigen Sie die Ziele Ihres Projekts, Designpräferenzen und Funktionsanforderungen bei Ihrer Entscheidung. Mit der richtigen Vorlage können Sie Ihre Webentwicklung mit Zuversicht starten.
      </p>
    `,
  },
  {
    id: 2,
    iconSrc: serviceTwo,
    titleEn: "E-commerce Solutions",
    titleDe: "E-Commerce-Lösungen",
    dateEn: "Tue 18 Aug 2024",
    dateDe: "Dienstag, 18. August 2024",
    descriptionEn:
      "From design to putting your website online, we create your custom website and web application.",
    descriptionDe:
      "Vom Design bis zur Online-Schaltung Ihrer Website erstellen wir Ihre individuelle Website und Webanwendung.",
    contentEn: `
      <h2>Building Effective E-commerce Solutions</h2>
      <p>
        Our E-commerce Solutions service focuses on helping businesses establish a strong online presence and drive sales. We provide end-to-end solutions, from designing your online store to implementing secure payment gateways and optimizing the user experience.
      </p>
      
      <h2>Key Features</h2>
      <ul>
        <li>Custom E-commerce Website Design</li>
        <li>Secure Payment Processing</li>
        <li>User-Friendly Shopping Cart</li>
        <li>Product Catalog Management</li>
        <li>Mobile-Friendly Design</li>
      </ul>
    `,
    contentDe: `
      <h2>Effektive E-Commerce-Lösungen aufbauen</h2>
      <p>
        Unser E-Commerce-Lösungen-Service konzentriert sich darauf, Unternehmen dabei zu helfen, eine starke Online-Präsenz zu etablieren und den Umsatz zu steigern. Wir bieten Komplettlösungen an, von der Gestaltung Ihres Online-Shops bis zur Implementierung sicherer Zahlungsgateways und zur Optimierung des Benutzererlebnisses.
      </p>
      
      <h2>Wichtige Funktionen</h2>
      <ul>
        <li>Individuelles E-Commerce-Webdesign</li>
        <li>Sichere Zahlungsabwicklung</li>
        <li>Benutzerfreundlicher Warenkorb</li>
        <li>Produktkatalogverwaltung</li>
        <li>Mobiles Design</li>
      </ul>
    `,
  },
  {
    id: 3,
    iconSrc: serviceThree,
    titleEn: "UI/UX Design",
    titleDe: "UI/UX Design",
    dateEn: "Tue 18 Aug 2024",
    dateDe: "Dienstag, 18. August 2024",
    descriptionEn:
      "Our talented designers craft intuitive user interfaces and captivating user experiences. We pay close attention to every detail, ensuring your website engages visitors and leaves a lasting impression.",
    descriptionDe:
      "Unsere talentierten Designer entwerfen intuitive Benutzeroberflächen und fesselnde Benutzererlebnisse. Wir legen großen Wert auf jedes Detail, um sicherzustellen, dass Ihre Website Besucher anspricht und einen bleibenden Eindruck hinterlässt.",
    contentEn: `
      <h2>The Essence of UI/UX Design</h2>
      <p>
        UI/UX Design is a vital aspect of creating user-friendly and visually appealing digital experiences. The role of a designer involves crafting interfaces that users love to interact with and ensuring a seamless journey. Dive into the world of UI/UX design to understand its significance and methodologies.
      </p>
      
      <h2>Key Principles of UI/UX Design</h2>
      <ul>
        <li>User-Centered Design: Putting the user at the center of the design process ensures that their needs and preferences are prioritized, resulting in a more relevant and engaging experience.</li>
        <li>Consistency: Maintaining a consistent design language across all elements of an interface helps users navigate and interact with ease, building a sense of familiarity.</li>
        <li>Clarity and Simplicity: Clear and simple designs eliminate confusion and make it easier for users to achieve their goals without unnecessary distractions or complexity.</li>
        <li>Accessibility: Designing for accessibility ensures that all users, including those with disabilities, can access and use the digital product effectively.</li>
        <li>Feedback and Affordance: Providing feedback for user actions and creating visual cues (affordances) help users understand how to interact with elements and the system.</li>
        <li>Emotional Design: Going beyond functionality, incorporating emotional elements into the design can create a memorable and delightful user experience.</li>
      </ul>
      
      <h2>Evolution of UI/UX Design</h2>
      <p>
        UI/UX design has evolved significantly over the years, adapting to technological advances and changing user expectations. From skeuomorphic designs that imitated real-world objects to the minimalist and flat design trends, the field continues to innovate to provide the best experiences.
      </p>
    `,
    contentDe: `
      <h2>Die Bedeutung von UI/UX Design</h2>
      <p>
        UI/UX Design ist ein wesentlicher Aspekt bei der Erstellung benutzerfreundlicher und visuell ansprechender digitaler Erlebnisse. Die Aufgabe eines Designers besteht darin, Benutzeroberflächen zu gestalten, mit denen Benutzer gerne interagieren, und sicherzustellen, dass die Benutzerreise reibungslos verläuft. Tauchen Sie ein in die Welt des UI/UX Designs, um seine Bedeutung und Methoden zu verstehen.
      </p>
      
      <h2>Wichtige Prinzipien des UI/UX Designs</h2>
      <ul>
        <li>Benutzerzentriertes Design: Die Platzierung des Benutzers im Mittelpunkt des Designprozesses gewährleistet, dass seine Bedürfnisse und Vorlieben priorisiert werden und zu einer relevanteren und ansprechenderen Erfahrung führen.</li>
        <li>Konsistenz: Die Beibehaltung einer konsistenten Designsprache in allen Elementen einer Benutzeroberfläche hilft Benutzern, sich einfach zurechtzufinden und zu interagieren und schafft ein Gefühl der Vertrautheit.</li>
        <li>Klarheit und Einfachheit: Klare und einfache Designs beseitigen Verwirrung und erleichtern es Benutzern, ihre Ziele ohne unnötige Ablenkungen oder Komplexität zu erreichen.</li>
        <li>Barrierefreiheit: Das Design für Barrierefreiheit gewährleistet, dass alle Benutzer, einschließlich derer mit Behinderungen, das digitale Produkt effektiv nutzen können.</li>
        <li>Feedback und Affordance: Die Bereitstellung von Rückmeldungen für Benutzeraktionen und die Schaffung visueller Hinweise (Affordances) helfen Benutzern zu verstehen, wie sie mit Elementen und dem System interagieren können.</li>
        <li>Emotionales Design: Über die Funktionalität hinaus kann die Integration emotionaler Elemente in das Design ein unvergessliches und erfreuliches Benutzererlebnis schaffen.</li>
      </ul>
      
      <h2>Entwicklung des UI/UX Designs</h2>
      <p>
        Das UI/UX Design hat sich im Laufe der Jahre erheblich weiterentwickelt, um sich technologischen Fortschritten und veränderten Benutzererwartungen anzupassen. Vom skeuomorphen Design, das reale Objekte nachahmte, bis zu den minimalistischen und flachen Design-Trends, die Branche bleibt innovativ, um die besten Erfahrungen zu bieten.
      </p>
    `,
  },
];

export default serviceData;
