import React from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import Work from "../components/Work";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const {t} = useTranslation();

  return (
    <>
      <Banner path={t(`portfolio.name_section`)} />
      <Work />
      <Question />
    </>
  );
};

export default Portfolio;
