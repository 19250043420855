import React from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import Service from "../components/Service";
import { useTranslation } from "react-i18next";

const ServicesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner path={t(`services.name_section`)} />
      <Service data="*" />
      <Question />
    </>
  );
};

export default ServicesPage;
