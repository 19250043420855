import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import transEn from "../locale/en.json";
import transDe from "../locale/de.json";

const resources = {
  en: {
    translation: transEn
  },
  de: {
    translation: transDe
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;