import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import blogsData from "../data/News";

// Import Images
import FirstImage from "../assets/images/news/img-1.jpg";
import SecImage from "../assets/images/news/img-2.jpg";
import ThirdImage from "../assets/images/news/img-3.jpg";
import avatarSrc from "../assets/images/news/author.png";

const SinglePost = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const blog = blogsData.find((item) => item.id === Number(id));
    if (!blog) {
      navigate(`/news`);
      return;
    }
    setContent(blog);
  }, [id]);

  const categories = ["blog 1", "test"];

  return (
    <>
      <Banner path={t(`services.name_section`).toLowerCase()} />
      <section className="blog-single-post section" data-aos="fade-up">
        <div className="container">
          <h2 className="post-title">
            {i18n.language === "en"
              ? content.titleEn
              : i18n.language === "de"
              ? content.titleDe
              : content.titleEn}
          </h2>

          <div className="columns is-multiline">
            <div className="column is-8">
              <article>
                <div className="post-header">
                  <figure className="image post-thumbnail">
                    <img
                      width="885"
                      height="500"
                      src={FirstImage}
                      alt="Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis hic quam obcaecati quidem vitae blanditiis, adipisci accusantium esse a soluta autem voluptatibus, repudiandae nisi sint sequi."
                    />
                  </figure>
                </div>
                <div className="post-categories tags">
                  {categories.map((category, index) => (
                    <a
                      href="#"
                      className="button bac-post-category-item tag"
                      key={index}
                    >
                      <span>{category}</span>
                    </a>
                  ))}
                </div>

                <div className="post-metatags is-flex is-align-items-center">
                  <div className="author is-flex is-align-items-center">
                    <figure className="image author-avatar tutor-ratio">
                      <img
                        className="is-rounded"
                        src={avatarSrc}
                        alt="achraf lafkiri full stack developer from morocco"
                      />
                    </figure>
                    <span className="author-name">Achraf Lafkiri</span>
                  </div>
                  <div className="post-publish-date is-flex is-align-items-center">
                    <span className="icon">
                      <i className="fa-solid fa-calendar-days"></i>
                    </span>
                    <h4 className="post-date">
                      {i18n.language === "en"
                        ? content.dateEn
                        : i18n.language === "de"
                        ? content.dateDe
                        : content.dateEn}
                    </h4>
                  </div>
                </div>

                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "en"
                        ? content.contentEn
                        : i18n.language === "de"
                        ? content.contentDe
                        : content.contentEn,
                  }}
                ></div>
              </article>
            </div>
            <div className="column is-4">
              <aside className="sidebar blog-single-post-sidebar">
                <div className="post-widget default-widget related-posts mb-4">
                  <div className="widget-header">
                    <h2 className="widget-title">
                      {t(`services.related_articles`)}
                    </h2>
                  </div>
                  <div className="widget-body">
                    <div className="related-post-item">
                      <div className="media">
                        <div className="media-left">
                          <figure className="image">
                            <img
                              width="2560"
                              height="1709"
                              src={SecImage}
                              className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                              alt="Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis hic quam obcaecati quidem vitae blanditiis, adipisci accusantium esse a soluta autem voluptatibus, repudiandae nisi sint sequi."
                            />
                          </figure>
                        </div>
                        <div className="media-content">
                          <h4>
                            <a href="#">The Role of Colors in UI/UX Design</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="related-post-item">
                      <div className="media">
                        <div className="media-left">
                          <figure className="image">
                            <img
                              src={ThirdImage}
                              alt="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita blanditiis voluptates labore, error quisquam illum aspernatur, dicta earum."
                            />
                          </figure>
                        </div>
                        <div className="media-content">
                          <h4>
                            <a href="#">
                              Crafting User-Centric Mobile Experiences
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-widget default-widget social-media mb-4">
                  <div className="widget-header">
                    <h2 className="widget-title">
                      <span>Connect with Us</span>
                    </h2>
                  </div>
                  <div className="widget-body">
                    <ul className="bac-social-media buttons">
                      <li>
                        <a className="button" href="#" target="_blank">
                          <span className="icon">
                            <i className="fa-brands fa-x-twitter"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="button" href="#" target="_blank">
                          <span className="icon">
                            <i className="fa-brands fa-instagram"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="button" href="#" target="_blank">
                          <span className="icon">
                            <i className="fa-brands fa-linkedin"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Question />
    </>
  );
};

export default SinglePost;
