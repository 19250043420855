import React from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import { useTranslation } from "react-i18next";
import blogsData from "../data/News";
import { Link } from "react-router-dom/dist";
import News from "../components/News";

const NewsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner path={t(`articles.name_section`)} />
      <News data="*" />
      <Question />
    </>
  );
};

export default NewsPage;
