import { useTranslation } from "react-i18next";
import aboutImage from "../assets/images/about/img-5.png";

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="about section mt-6" data-aos="fade-up">
      <div className="mycontainer">
        <div className="columns is-vcentered">
          {/* COLUMN START */}
          <div className="column">
            <div className="profile-img page-2">
              <figure className="image">
                <img src={aboutImage} alt="achraf lafkiri" />
              </figure>
            </div>
          </div>
          {/* COLUMN END */}
          {/* COLUMN START */}
          <div className="column">
            <div className="info">
              <h1 className="section-title">{t("about.name_section")}</h1>
              <p>{t("about.desc")}</p>
              <div className="myinfos page-2">
                <ul className="columns is-vcentered has-blur page-2">
                  <li>
                    {" "}
                    {t("about.name")} :{" "}
                    <span className="">{t("about.your_info.name")}</span>
                  </li>
                  <li>
                    {" "}
                    {t("about.age")} :{" "}
                    <span className="">{t("about.your_info.age")}</span>
                  </li>
                  <li>
                    {" "}
                    {t("about.address")} :{" "}
                    <span className="">{t("about.your_info.address")}</span>
                  </li>
                  <li>
                    {" "}
                    {t("about.email")} :{" "}
                    <span className="">{t("about.your_info.email")}</span>
                  </li>
                </ul>
              </div>
              <div className="buttons mt-6">
                <a href="#" className="bx-btn custom-btn">
                  {t(`about.download_cv`)}
                </a>
              </div>
            </div>
          </div>
          {/* COLUMN END */}
        </div>
      </div>
    </section>
  );
};

export default About;
