import React, { useEffect } from "react";
import Typed from "typed.js";
import shapeOne from "../assets/images/bg/shape-1.png";
import shapeTwo from "../assets/images/bg/shape-2.png";
import heroImage from "../assets/images/bg/img-1.png";
import heroShape from "../assets/images/bg/hero-shape.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next"; // Import i18next

const Hero = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Access the language from i18next
    const language = i18next.language;

    const options = {
      strings:
        language === "de"
          ? ["Webentwickler", "UI/UX Designer"]
          : ["Web Developer", "UI/UX Designer"],
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 2000,
      startDelay: 1000,
      loop: true,
    };

    const typed = new Typed("#typed", options);

    return () => {
      typed.destroy();
    };
  }, [t]);

  return (
    <section
      id="home"
      className="hero-section active bg-shape-hero"
      data-aos="fade-up"
    >
      <img src={heroShape} className="hero-shape.png" alt="shape" />
      <div className="mycontainer">
        <div className="columns is-vcentered">
          {/* COLUMN START */}
          <div className="column">
            {/* MAIN INFO START */}
            <div className="info">
              <h3 className="hero-section-me">
                {t(`hero.my_name_is`)}
                <span className="has-text-weight-bold ml-2">{t(`hero.name`)}</span>
              </h3>
              <h1 className="hero-section-title">
              {t(`hero.I_am_a`)}<span id="typed"></span>
              </h1>
              <small className="hero-section-p">{t(`hero.desc`)}</small>
              <div className="buttons">
                <img id="shape1" src={shapeOne} alt="" />
                <Link className="custom-btn bx-btn" to="">
                {t("hero.hireme")}
                </Link>
              </div>
            </div>
            {/* MAIN INFO END */}
          </div>
          {/* COLUMN END */}
          {/* COLUMN START */}
          <div className="column">
            <div className="profile-img">
              <figure className="image">
                <img id="shape2" src={shapeTwo} alt="" />
                <img src={heroImage} alt="" />
              </figure>
            </div>
          </div>
          {/* COLUMN END */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
