import React from "react";
import Banner from "../components/Banner";
import secondImage from "../assets/images/about/shape-1.png";
import Question from "../components/Question";
import aboutImage from "../assets/images/about/img-5.png";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner path={t(`about.name_section`)} />
      <section id="about" className="about page-2" data-aos="fade-up">
        <div className="mycontainer">
          <section id="about" className="about section" data-aos="fade-up">
            <div className="mycontainer">
              <div className="columns is-vcentered">
                {/* COLUMN START */}
                <div className="column">
                  <div className="profile-img page-2">
                    <figure className="image">
                      <img src={aboutImage} alt="achraf lafkiri" />
                    </figure>
                  </div>
                </div>
                {/* COLUMN END */}
                {/* COLUMN START */}
                <div className="column">
                  <div className="info">
                    <h1 className="section-title">{t("about.name_section")}</h1>
                    <p>{t("about.desc")}</p>
                    <div className="myinfos page-2">
                      <ul className="columns is-vcentered has-blur page-2">
                        <li>
                          {" "}
                          {t("about.name")} :{" "}
                          <span className="">{t("about.your_info.name")}</span>
                        </li>
                        <li>
                          {" "}
                          {t("about.age")} :{" "}
                          <span className="">{t("about.your_info.age")}</span>
                        </li>
                        <li>
                          {" "}
                          {t("about.address")} :{" "}
                          <span className="">
                            {t("about.your_info.address")}
                          </span>
                        </li>
                        <li>
                          {" "}
                          {t("about.email")} :{" "}
                          <span className="">{t("about.your_info.email")}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="buttons mt-6">
                      <a href="#" className="bx-btn custom-btn">
                        {t(`about.download_cv`)}
                      </a>
                    </div>
                  </div>
                </div>
                {/* COLUMN END */}
              </div>
            </div>
          </section>
          <div
            className="columns is-vcentered mt-6"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-anchor-placement="top-center"
          >
            <div className="column">
              <div className="info">
                <h3 className="section-title">
                  <span className="has-text-dark">Our</span> Vision
                </h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Mollitia quas rerum voluptates facilis nostrum accusantium
                  nisi, id ut sapiente odit. Vero suscipit placeat molestiae
                  consectetur quibusdam, iure omnis est optio.
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Mollitia quas rerum voluptates facilis nostrum accusantium
                  nisi, id ut sapiente odit. Vero suscipit placeat molestiae
                  consectetur quibusdam, iure omnis est optio.
                </p>
              </div>
            </div>
            <div className="column">
              <div className="profile-img">
                <figure className="image">
                  <img src={secondImage} alt="lorem ipsum" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Question />
    </>
  );
};

export default AboutPage;
