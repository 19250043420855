import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Banner = ({ path }) => {
  const { t } = useTranslation();

  return (
    <div className="banner">
      <div className="bac-banner">
        <div className="is-overlay"></div>
        <div className="banner-content">
          <nav className="breadcrumb is-centered" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to="/" className="has-text-grey-light is-lowercase">
                  {t(`navbar.home`)}
                </Link>
              </li>
              <li className="has-text-primary-light is-lowercase">{path}</li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Banner;
