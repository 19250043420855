import React, { useEffect, useRef } from "react";
import mixitup from "mixitup";

import worksData from "../data/works";
import { useTranslation } from "react-i18next";

const Work = () => {
  const { t } = useTranslation();

  const containerRef = useRef(null);

  useEffect(() => {
    const mixer = mixitup(containerRef.current, {
      selectors: {
        target: ".tab-content", // Selector for the portfolio items
      },
      animation: {
        duration: 300, // Animation duration in milliseconds
      },
    });

    return () => {
      mixer.destroy();
    };
  }, []);

  return (
    <section id="portfolio" className="portfolio section" data-aos="fade-up">
      <div className="mycontainer">
        {/* TITLE AND DESCRIPTION START */}
        <div className="infos mb-6">
          <h2 className="section-title has-text-centered">
            {t("portfolio.name_section")}
          </h2>
          <p className="has-text-centered m-auto">{t("portfolio.subtitle")}</p>
        </div>
        {/* TITLE AND DESCRIPTION END */}

        {/* TABS START */}
        <div className="tabs is-centered">
          <ul>
            <li data-filter="*" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <i className="fas fa-image" aria-hidden="true"></i>
                </span>
                <span>{t("portfolio.tabs.all")}</span>
              </a>
            </li>
            <li data-filter=".design" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <i className="fas fa-music" aria-hidden="true"></i>
                </span>
                <span>{t("portfolio.tabs.design")}</span>
              </a>
            </li>
            <li data-filter=".development" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <i className="fas fa-code" aria-hidden="true"></i>
                </span>
                <span>{t("portfolio.tabs.development")}</span>
              </a>
            </li>
          </ul>
        </div>
        {/* TABS END */}

        {/* PORTFOLIO CONTENT START */}
        <div className="portfolio-content" ref={containerRef}>
          {worksData.map((item, index) => (
            <div key={index} className={`tab-content ${item.category}`}>
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={item.imageUrl} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* PORTFOLIO CONTENT END */}
      </div>
    </section>
  );
};

export default Work;
