const experiences = [
    {
      titleEn: "Web designer",
      titleDe: "Webdesigner",
      companyEn: "Fannous Company, Prague",
      companyDe: "Fannous Unternehmen, Prag",
      dateEn: "June 2012 - April 2014",
      dateDe: "Juni 2012 - April 2014",
      descriptionEn:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt quasi perspiciatis, aliquid sed maiores accusamus. Adipisci quidem nostrum quos quae doloremque esse a, ipsum earum, recusandae omnis dignissimos et sint.",
      descriptionDe:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt quasi perspiciatis, aliquid sed maiores accusamus. Adipisci quidem nostrum quos quae doloremque esse a, ipsum earum, recusandae omnis dignissimos et sint.",
    },
    {
      titleEn: "UI / UX design",
      titleDe: "UI / UX Design",
      companyEn: "Fannous Company, Prague",
      companyDe: "Fannous Unternehmen, Prag",
      dateEn: "June 2012 - April 2014",
      dateDe: "Juni 2012 - April 2014",
      descriptionEn:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt quasi perspiciatis, aliquid sed maiores accusamus. Adipisci quidem nostrum quos quae doloremque esse a, ipsum earum, recusandae omnis dignissimos et sint.",
      descriptionDe:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt quasi perspiciatis, aliquid sed maiores accusamus. Adipisci quidem nostrum quos quae doloremque esse a, ipsum earum, recusandae omnis dignissimos et sint.",
    },
  ];
  
  export default experiences;
  