import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <div className="mycontainer">
        <div className="main">
          <p className="has-text-centered">
            Copyright 2024. Design and development: <a href="https://www.templatemonster.com/authors/achrafthemes/">AchrafThemes - <span>Achraf LAFKIRI</span></a> 
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
