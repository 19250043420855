import React from "react";

const Loading = () => {
  return (
    <div className="loader-page">
            <div className="whirly-loader"></div>
        </div>
  );
};

export default Loading;
