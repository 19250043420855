import workOne from "../assets/images/portfolio/img-1.jpg";
import workTwo from "../assets/images/portfolio/img-2.jpg";
import workThree from "../assets/images/portfolio/img-3.jpg";
import workFour from "../assets/images/portfolio/img-4.jpg";

const worksData = [
  {
    category: "design",
    imageUrl: workOne,
  },
  {
    category: "development",
    imageUrl: workTwo,
  },
  {
    category: "development",
    imageUrl: workThree,
  },
  {
    category: "templates",
    imageUrl: workFour,
  },
];

export default worksData;
